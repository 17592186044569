import React from 'react';
import Navbar from './navbar';
import './homepage.css'; // You can rename this to student-homepage.css if preferred

const StudentHomepage = () => {
    return (
        <div>
            <Navbar />
            <div className="homepage-content">
                <h1>Welcome to MyApp!</h1>
                <p>This is your student homepage. You can add more content here.</p>
                <p>Explore the links in the navbar to navigate through the app.</p>
            </div>
        </div>
    );
};

export default StudentHomepage;