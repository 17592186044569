// Modal.js
import React, { useState } from 'react';
import './modal.css';

const Modal = ({ isOpen, onClose, onCreate }) => {
    const [category, setCategory] = useState('Junior');

    if (!isOpen) return null;

    const handleCreate = (e) => {
        e.preventDefault();
        const name = e.target.name.value;
        const email = e.target.email.value;
        const password = e.target.password.value;

        onCreate(name, email, password, category);
        onClose();
    };

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Create Student</h2>
                <form onSubmit={handleCreate}>
                    <label>Name</label>
                    <input type="text" name="name" placeholder="Enter student name" required />
                    
                    <label>Email</label>
                    <input type="email" name="email" placeholder="Enter student email" required />
                    
                    <label>Password</label>
                    <input type="password" name="password" placeholder="Enter password" required />
                    
                    <label>Category</label>
                    <select value={category} onChange={(e) => setCategory(e.target.value)} required>
                        <option value="Junior">Junior</option>
                        <option value="Senior">Senior</option>
                    </select>

                    <div className="button-group">
                        <button type="button" className="button close-button" onClick={onClose}>Close</button>
                        <button type="submit" className="button submit-button">Create Student</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Modal;
