import React, { useState, useEffect } from 'react';
import './modal.css';
import { getDatabase, ref, set } from 'firebase/database';

const EditModal = ({ isOpen, onClose, student, onEdit }) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [category, setCategory] = useState('Junior');

    useEffect(() => {
        // Pre-fill form with existing student data when modal opens
        if (student) {
            setName(student.name || '');
            setEmail(student.email || '');
            setCategory(student.category || 'Junior');
        }
    }, [student]);

    if (!isOpen) return null;

    const handleEdit = (e) => {
        e.preventDefault();

        const updatedStudent = {
            ...student,
            name,
            email,
            category,
        };

        const db = getDatabase();
        const studentRef = ref(db, `students/${student.id}`);
        
        // Update the student data in Firebase
        set(studentRef, updatedStudent)
            .then(() => {
                onEdit(updatedStudent); // Update the student data on the page
                onClose();
            })
            .catch((error) => {
                console.error("Error updating student: ", error);
            });
    };

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Edit Student</h2>
                <form onSubmit={handleEdit}>
                    <label>Name</label>
                    <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Enter student name"
                        required
                    />
                    
                    <label>Email</label>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter student email"
                        required
                    />
                    
                    <label>Category</label>
                    <select
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                        required
                    >
                        <option value="Junior">Junior</option>
                        <option value="Senior">Senior</option>
                    </select>

                    <div className="button-group">
                        <button
                            type="button"
                            className="button close-button"
                            onClick={onClose}
                        >
                            Close
                        </button>
                        <button type="submit" className="button submit-button">
                            Save Changes
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditModal;
