import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import LoginForm from "./login"; // Import the Login component
import StudentHomepage from './StudentHomepage'; // Adjusted import
import StaffHomepage from "./StaffHomepage";
import UploadPage from "./UploadPage"; // Import the UploadPage component
import StudentPage from "./studentpage";
import Resource from "./resource"; // Import the Resource component

const App = () => {
  const location = useLocation();

  return (
    <div>
      {/* Routes for different pages */}
      <Routes>
        <Route path="/" element={<LoginForm />} /> {/* This will be the only route for the login */}
        {/* Add userId as a URL parameter */}
        <Route path="/student/:userId" element={<StudentHomepage />} />
        <Route path="/staff/:userId" element={<StaffHomepage />} />
        {/* Add the StudentList route, only accessible for staff */}
        <Route path="/staff/:userId/students" element={<StudentPage />} /> {/* Route for student list */}
        <Route path="/staff/:userId/upload" element={<UploadPage />} />
        <Route path="/student/:userId/resources" element={<Resource />} /> {/* New route for resources */}
      </Routes>
    </div>
  );
};

export default App;
