import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './navbar.css'; // Make sure to create a CSS file for styling
import logo from './logo.jpg'; // Adjust the path as necessary

const Navbar = () => {
    const navigate = useNavigate();

    const handleLogout = () => {
        // Clear user data from local storage or session storage
        localStorage.removeItem('user'); // Change 'user' to your actual key
        // Navigate to the homepage
        navigate('/');
    };

    return (
        <nav className="navbar">
            <div className="navbar-logo">
                <Link to="/">
                    <img src={logo} alt="MyApp Logo" style={{ width: '100px', height: '60px', marginRight: '8px' }} />
                </Link>
            </div>
            <ul className="navbar-links">
                <li>
                    <Link to="/">Home</Link>
                </li>
                <li>
                    <Link to="/about">About</Link>
                </li>
                <li>
                    <Link to="/student/:userId/resources">Resources</Link>
                </li>
                <li>
                    <Link to="/contact">Contact</Link>
                </li>
            </ul>
            <div className="navbar-auth">
                <button onClick={handleLogout} style={{ background: 'none', border: 'none', color: 'white', cursor: 'pointer' }}>
                    Logout
                </button>
            </div>
        </nav>
    );
};

export default Navbar;
