import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { loginUser } from "./auth"; // Import your login function
import logo from "./logo.jpg";
import "./login.css";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [domain, setDomain] = useState("student");
  const navigate = useNavigate();

  const handleLogin = async () => {
    // Check for staff login restrictions
    if (domain === "staff" && email !== "admin123@gmail.com") {
      alert("Only admin123@gmail.com can log in as staff.");
      return;
    }

    const userId = await loginUser(email, password); // Get a new userId from loginUser
    if (userId) {
      localStorage.setItem("authToken", userId); // Store the new userId as the token

      // Determine the redirect path based on the selected domain
      const redirectPath = domain === "student" ? `/student/${userId}` : `/staff/${userId}`;
      navigate(redirectPath); // Navigate to either /student/:userId or /staff/:userId
      
      // Reset email and password after successful login
      setEmail("");
      setPassword("");
    } else {
      alert("Invalid credentials, please try again.");
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <img src={logo} alt="Temasek Polytechnic Logo" className="logo" />
        <h2>TSC Education Lab</h2>
        <p>Please select a domain:</p>
        <div className="radio-group">
          <label>
            <input
              type="radio"
              name="domain"
              value="student"
              checked={domain === "student"}
              onChange={() => setDomain("student")}
            />
            Student
          </label>
          <label>
            <input
              type="radio"
              name="domain"
              value="staff"
              checked={domain === "staff"}
              onChange={() => setDomain("staff")}
            />
            Staff
          </label>
        </div>
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="input-group">
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="input-group">
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button onClick={handleLogin} className="login-button">Login</button>
        </form>
        <footer>
          <p>&copy;2019 Temasek Polytechnic. All Rights Reserved.</p>
          <div className="footer-links">
            <a href="#">Report Vulnerability</a>
            <a href="#">Terms of Use</a>
            <a href="#">Privacy Statement</a>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default Login;
