import React, { useEffect, useState } from 'react';
import { getStorage, ref as storageRef, listAll, getDownloadURL } from 'firebase/storage';
import { useAuth } from './AuthContext';
import { db } from './firebaseConfig'; // Ensure to import your Firebase configuration
import { getDatabase, ref as dbRef, get } from 'firebase/database'; // Import functions for Realtime Database

const Resource = () => {
    const { currentUser } = useAuth();
    const [mediaFiles, setMediaFiles] = useState([]);
    const [category, setCategory] = useState('');
    
    // Fetch the user's category (Junior or Senior) from Realtime Database
    useEffect(() => {
        if (currentUser) {
            fetchUserCategory(currentUser.uid);
        }
    }, [currentUser]);

    const fetchUserCategory = async (userId) => {
        try {

            const db = getDatabase();

            const userRef = dbRef(db, `students/${userId}`); // Correctly reference the database path
            const userSnapshot = await get(userRef);
            
            if (userSnapshot.exists()) {
                const userData = userSnapshot.val();
                setCategory(userData.category); // Set the category (Junior or Senior) in state
            } else {
                console.error('No such user document!');
            }
        } catch (error) {
            console.error('Error fetching user category:', error);
        }
    };

    // Fetch media files from Firebase Storage based on the user's category
    useEffect(() => {
        if (category) {
            fetchMediaFiles(category);
        }
    }, [category]);

    const fetchMediaFiles = async (category) => {
        const storage = getStorage();
        const listRef = storageRef(storage, `${category}`); // Only using the category as the path

        try {
            const res = await listAll(listRef);
            const filesPromises = res.items.map(async (itemRef) => {
                const url = await getDownloadURL(itemRef);
                return { name: itemRef.name, url };
            });

            const files = await Promise.all(filesPromises);
            setMediaFiles(files);
        } catch (error) {
            console.error('Error fetching media files:', error);
        }
    };

    return (
        <div>
            <h1>Resources</h1>
            <h2>Category: {category}</h2>
            <h3>Your Recordings:</h3>
            <ul>
                {mediaFiles.length > 0 ? (
                    mediaFiles.map((file) => (
                        <li key={file.name}>
                            {file.name.endsWith('.mp3') ? (
                                <audio controls>
                                    <source src={file.url} type="audio/mpeg" />
                                    Your browser does not support the audio element.
                                </audio>
                            ) : (
                                <video controls width="300">
                                    <source src={file.url} type="video/mp4" />
                                    Your browser does not support the video element.
                                </video>
                            )}
                        </li>
                    ))
                ) : (
                    <p>No recordings available for your category.</p>
                )}
            </ul>
        </div>
    );
};

export default Resource;
