import React, { useState, useEffect } from 'react';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { getDatabase, ref, set, onValue } from 'firebase/database';
import Navbar1 from './navbar1';
import Modal from './Modal';
import EditModal from './editModal'; // Import the EditModal component
import './studentpage.css';

const StudentPage = () => {
    const [students, setStudents] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);
    const [isEditModalOpen, setEditModalOpen] = useState(false); // State for Edit Modal
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedStudent, setSelectedStudent] = useState(null); // State for selected student to edit

    useEffect(() => {
        setLoading(true);
        const db = getDatabase();
        const studentsRef = ref(db, 'students');

        const unsubscribe = onValue(studentsRef, (snapshot) => {
            const data = snapshot.val();
            const studentList = data
                ? Object.entries(data).map(([key, value]) => ({
                      id: key,
                      ...value,
                  }))
                : [];
            setStudents(studentList);
            setLoading(false);
        });

        return () => {
            unsubscribe();
        };
    }, []);

    const handleCreateFromModal = async (name, email, password, category) => {
        const auth = getAuth();
        const db = getDatabase();
        setLoading(true);

        try {
            const userCredential = await createUserWithEmailAndPassword(
                auth,
                email,
                password
            );
            const user = userCredential.user;

            await set(ref(db, 'students/' + user.uid), {
                name: name,
                email: email,
                category: category,
                createdAt: new Date().toISOString(),
            });
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    const handleEditStudent = (student) => {
        setSelectedStudent(student);
        setEditModalOpen(true);
    };

    const handleSaveEdit = (updatedStudent) => {
        setStudents((prevStudents) =>
            prevStudents.map((student) =>
                student.id === updatedStudent.id ? updatedStudent : student
            )
        );
    };

    const filteredStudents = students.filter((student) =>
        student.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className="student-page">
            <Navbar1 />
            <h2 className="page-title">Student List</h2>

            <button
                onClick={() => setModalOpen(true)}
                className="add-student-button"
            >
                Add Student
            </button>
            <Modal
                isOpen={isModalOpen}
                onClose={() => setModalOpen(false)}
                onCreate={handleCreateFromModal}
            />

            <EditModal
                isOpen={isEditModalOpen}
                onClose={() => setEditModalOpen(false)}
                student={selectedStudent}
                onEdit={handleSaveEdit}
            />

            <input
                type="text"
                placeholder="search by roll"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="search-bar"
            />

            {loading ? (
                <p className="loading-text">Loading students...</p>
            ) : (
                <table className="student-table">
                    <thead>
                        <tr>
                            <th>Roll</th>
                            <th>Student Name</th>
                            <th>Gender</th>
                            <th>Phone Number</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredStudents.length === 0 ? (
                            <tr>
                                <td colSpan="5" className="no-students">
                                    No students found.
                                </td>
                            </tr>
                        ) : (
                            filteredStudents.map((student) => (
                                <tr key={student.id}>
                                    <td>{student.id}</td>
                                    <td>{student.name}</td>
                                    <td>{student.category}</td>
                                    <td>{student.email}</td>
                                    <td>
                                        <button
                                            className="action-button"
                                            onClick={() =>
                                                handleEditStudent(student)
                                            }
                                        >
                                            Edit
                                        </button>
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            )}

            <div className="pagination">
                <button className="pagination-button">Previous</button>
                <button className="pagination-button">Next</button>
            </div>
        </div>
    );
};

export default StudentPage;
