import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage"; // Import getDownloadURL

const storage = getStorage();

export const uploadRecording = async (file, userId, category) => {
  try {
    // Define the storage reference based on category
    const storageRef = ref(storage, `recordings/${category}/${userId}/${file.name}`);

    // Upload file to Firebase Storage
    const snapshot = await uploadBytes(storageRef, file);

    // Get the download URL after successful upload
    const downloadURL = await getDownloadURL(snapshot.ref);

    console.log("File uploaded successfully:", downloadURL);
    return downloadURL;
  } catch (error) {
    console.error("Error uploading file:", error);
    throw error;
  }
};

