import React, { useEffect } from 'react';
import Navbar from './navbar1';
import './staffhomepage.css';
import { FaUserPlus, FaUsers, FaUpload, FaChartBar, FaBell, FaSearch } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const StaffHomepage = () => {
    const navigate = useNavigate(); // Initialize navigate function

    useEffect(() => {
        // Fade-in effect for homepage content
        const content = document.querySelector('.homepage-content');
        content.classList.add('fade-in');
    }, []);

    return (
        <div className="staff-homepage">
            <Navbar />
            <div className="homepage-content">
                <h1>Welcome to MyApp!</h1>
                <p className="intro-text">This is your staff homepage. You can manage various tasks from here.</p>


                <div className="quick-access">
                    <h2>Quick Access</h2>
                    <div className="access-cards">
                        <div className="card">
                            <FaUserPlus className="card-icon" />
                            <h3>Add Student</h3>
                            <p>Create and manage student accounts easily.</p>
                            <button onClick={() => {/* Navigate to Add Student page */}}>Go to Add Student</button>
                        </div>
                        <div className="card">
                            <FaUsers className="card-icon" />
                            <h3>View Students</h3>
                            <p>Check the list of all registered students.</p>
                            <button onClick={() => {/* Navigate to Student List page */}}>View Students</button>
                        </div>
                        <div className="card">
                            <FaUpload className="card-icon" />
                            <h3>Upload Records</h3>
                            <p>Upload student records and materials.</p>
                            <button onClick={() => navigate('/staff/upload')}>Upload Records</button> {/* Navigate to UploadPage */}
                        </div>
                        <div className="card">
                            <FaChartBar className="card-icon" />
                            <h3>Reports</h3>
                            <p>Generate and view student performance reports.</p>
                            <button onClick={() => {/* Navigate to Reports page */}}>View Reports</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StaffHomepage;
