import React, { useState } from "react";
import { uploadRecording } from "./uploadService"; // Import the upload service
import { storage } from './firebaseConfig';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import './UploadPage.css'; // Import CSS file for styles
import Navbar from './navbar1';
import Card from './Card'; // Import the Card component

const UploadPage = () => {
  const [files, setFiles] = useState([]);
  const [message, setMessage] = useState("");
  const [category, setCategory] = useState("Junior"); // Default category
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({});

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
  };

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFiles = Array.from(event.dataTransfer.files);
    setFiles((prevFiles) => [...prevFiles, ...droppedFiles]);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleUpload = async () => {
    if (files.length === 0) {
      setMessage("Please select files to upload.");
      return;
    }

    setLoading(true);
    setShowSuccess(false);
    const newDownloadURLs = [];

    try {
      for (const [index, file] of files.entries()) {
        // Check file type based on category
        const validTypes = category === "Junior" ? ["video/mp4"] : ["audio/mpeg"];
        if (!validTypes.includes(file.type)) {
          setMessage(`Only ${category} files are allowed.`);
          continue;
        }

        // Define storage reference based on category only
        const storageRef = ref(storage, `${category}/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadProgress((prevProgress) => ({
              ...prevProgress,
              [index]: progress,
            }));
            console.log(`Upload is ${progress.toFixed(2)}% done for file: ${file.name}`);
          },
          (error) => {
            console.error("Error uploading file:", error);
            setMessage(`Failed to upload file: ${file.name}`);
          },
          async () => {
            const url = await getDownloadURL(uploadTask.snapshot.ref);
            newDownloadURLs.push(url);
            console.log("File available at", url);
          }
        );
      }

      setShowSuccess(true);
      setFiles([]);
    } catch (error) {
      setMessage(`Error uploading files: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const removeFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  return (
    <div className="upload-page">
      <Navbar />
      {showSuccess && <Card />}
      <div className="upload-card">
        <h2>Upload Recording</h2>
        <select value={category} onChange={handleCategoryChange} className="category-select">
          <option value="Junior">Junior (MP4)</option>
          <option value="Senior">Senior (MP3)</option>
        </select>

        <div
          className="drop-area"
          onDrop={handleDrop}
          onDragOver={handleDragOver}
        >
          <p>Drag & Drop files here or click to upload</p>
          <input
            type="file"
            accept=".mp3,.mp4"
            onChange={handleFileChange}
            multiple
            className="file-input"
          />
        </div>

        <div className="file-previews">
          {files.map((file, index) => (
            <div key={index} className="file-preview">
              <p>{file.name}</p>
              <button className="remove-button" onClick={() => removeFile(index)}>Remove</button>
              {uploadProgress[index] !== undefined && (
                <p>Upload Progress: {uploadProgress[index].toFixed(2)}%</p>
              )}
            </div>
          ))}
        </div>

        <button onClick={handleUpload} className="upload-button" disabled={loading}>
          {loading ? <div className="spinner"><div className="loader"></div></div> : "Upload"}
        </button>

        {message && <p className={message.startsWith("Error") ? "error-message" : "success-message"}>{message}</p>}
      </div>
    </div>
  );
};

export default UploadPage;
