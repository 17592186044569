// src/firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage"; // Import Storage
import { getFirestore } from "firebase/firestore"; // Import Firestore

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDq9lSfnIwmW71aVCwsszjPjgJmh2WuULU",
  authDomain: "mp4-cma.firebaseapp.com",
  projectId: "mp4-cma",
  storageBucket: "mp4-cma.appspot.com",
  messagingSenderId: "719027020516",
  appId: "1:719027020516:web:969d59ddb02c08574a42f1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const storage = getStorage(app); // Initialize Storage
const db = getFirestore(app); // Initialize Firestore

export { auth, storage, db }; // Ensure you export auth, storage, and db for use in other modules
