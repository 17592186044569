// src/auth.js
import { auth } from "./firebaseConfig"; // Ensure this points to the correct file
import { signInWithEmailAndPassword, onAuthStateChanged as firebaseOnAuthStateChanged } from "firebase/auth";

export const loginUser = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    return await userCredential.user.getIdToken(); // Return the user token
  } catch (error) {
    console.error("Login failed:", error);
    return null; // Handle login failure
  }
};

export const onAuthStateChanged = firebaseOnAuthStateChanged; // Correct export for onAuthStateChanged
