import React, { useState } from 'react';
import './Card.css'; // Import CSS file for styles

const Card = () => {
  const [isVisible, setIsVisible] = useState(true); // State to control visibility

  const handleClose = () => {
    setIsVisible(false); // Set visibility to false when close button is clicked
  };

  if (!isVisible) {
    return null; // Do not render the alert if it's not visible
  }

  return (
    <div className="card-alert"> {/* Update the class name here */}
      <button 
        type="button" 
        aria-label="close-success" 
        className="close-button absolute right-4 p-1 rounded-md transition-opacity opacity-40 hover:opacity-100"
        onClick={handleClose} // Add onClick event to handle close
      >
        <svg 
          stroke="currentColor" 
          fill="none" 
          strokeWidth={2} 
          viewBox="0 0 24 24" 
          strokeLinecap="round" 
          strokeLinejoin="round" 
          height={16} 
          width={16} 
          className="sizer [--sz:16px] h-4 w-4" 
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M18 6 6 18" />
          <path d="m6 6 12 12" />
        </svg>
      </button>
      <p className="flex flex-row items-center mr-auto gap-x-2">
        <svg 
          stroke="currentColor" 
          fill="none" 
          strokeWidth={2} 
          viewBox="0 0 24 24" 
          strokeLinecap="round" 
          strokeLinejoin="round" 
          height={28} 
          width={28} 
          className="icon h-7 w-7" 
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2Zm0 14a1 1 0 0 1-1-1v-3a1 1 0 1 1 2 0v3a1 1 0 0 1-1 1Zm0-7a1.5 1.5 0 1 1 1.5-1.5A1.5 1.5 0 0 1 12 9Z" />
        </svg>
        Successfully uploaded file
      </p>
    </div>
  );
};

export default Card;
