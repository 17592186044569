import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './navbar1.css';
import logo from './logo.jpg';
import { AuthContext } from './AuthContext'; // Adjust the path as necessary

const Navbar = () => {
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('authtoken'); // Remove the token
        navigate('/'); // Navigate back to the login page
    };

    return (
        <nav className="navbar">
            <div className="navbar-logo">
                <Link to="/staff">
                    <img src={logo} alt="MyApp Logo" style={{ width: '100px', height: '60px' }} />
                </Link>
            </div>
            <ul className="navbar-links">
                <li>
                    <Link to="/staff/:userId">Home</Link>
                </li>
                <li>
                    <Link to="/about">About</Link>
                </li>
                <li>
                    <Link to="/staff/:userId/upload">Services</Link>
                </li>
                <li>
                    <Link to="/staff/:userId/students">Student List</Link>
                </li>
            </ul>
            <div className="navbar-auth">
                <button
                    onClick={handleLogout}
                    className="logout-button"
                    aria-label="Logout"
                >
                    Logout
                </button>
            </div>
        </nav>
    );
};

export default Navbar;
